import * as React from "react"
import SiteContainer from "../../../components/site-container";
import {Helmet} from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import analytics from "../../../images/vectors/undraw_Analytics_re_dkf8.svg"
import measure_realtime from '../../../images/vectors/undraw_Real_time_sync_re_nky7.svg'
import PrivacySection from "../../../components/PrivacySection";
import PricingSection from "../../../components/PricingSection";

import CodeIcon from '@mui/icons-material/Code';
import salesforce from '../../../images/icons/salesforce.svg'
import CookieIcon from '@mui/icons-material/Cookie';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined';
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AllInclusiveOutlinedIcon from '@mui/icons-material/AllInclusiveOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import HttpIcon from '@mui/icons-material/Http';

import CoreConcept from "../../../components/CoreConcept";
import firstparty_analytics_dashboard
    from "../../../images/pages/solutions/analytics/firstparty-analytics-dashboard-sources.jpg";

const IndexPage = () => {
    return (
        <SiteContainer>
            <Helmet>
                <title>Firstparty Solutions - Analytics for Marketers and Developers</title>
                <meta name="description" content="Keep first-party cookies from your own domain, collect events in any browser, and move everything securely to your data warehouse." />
            </Helmet>

            <div className="section py-6 py-md-11 bg-gradient-white-light">
                <div className="container">
                    <div className="row justify-content-between align-items-center mb-10">
                        <div className="col-12 col-md-6">
                            <h1 className="display-3 fw-bold" style={{fontSize:'2.9rem'}}>
                                Free Web Analytics for <br />Marketers & Developers
                            </h1>
                            <p className="lead text-muted mb-6">
                                Collect data from all customer interactions and see a complete picture of the customer journey.
                            </p>
                            <div className="d-grid gap-2 d-md-block mb-8 mb-md-0">
                                <a className="btn btn-primary mx-md-2" href="/signup/">
                                    Get Started for Free<i className="fe fe-arrow-right ms-2" />
                                </a>
                                <a className="btn btn-outline-primary" href="/contact/">
                                    Contact Us<i className="fe fe-arrow-right ms-2" />
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 text-center">
                            <div className="img-skewed img-skewed-start mb-8 mb-md-0">
                                <img className="screenshot img-fluid mw-md-130 img-skewed-item"
                                     src={firstparty_analytics_dashboard} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="py-8 py-md-12">
                <div className="container">
                    <div className="row mb-9 justify-content-center">
                        <div className="col-12 col-lg-8 text-center">
                            <h2>Enterprise-grade Marketing Analytics. <br />No cost for your first 10 million monthly events.</h2>
                            <p className="text-gray-800">
                                Firstparty Analytics was built to help you understand your customers and improve your marketing
                                without breaking your budget.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-n7">
                        <div className="expandable-card col-12 col-sm-6 col-lg-4 mt-7">
                            <div className="card h-100 bg-light rounded-2">
                                <div className="card-body p-5 pb-0 my-auto text-dark">
                                    <CodeIcon style={{fontSize: '80px'}} />
                                </div>
                                <div className="card-body p-5">
                                    <h3 className="fw-bold text-dark">
                                        Easy Website Installation
                                    </h3>
                                    <p className="text-muted mb-0">
                                        Collect data with Firstparty.js, our lightweight Javascript library
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mt-7">
                            <div className="card h-100 bg-light rounded-2">
                                <div className="card-body p-5 pb-0 my-auto text-primary">
                                    <div className="img-fluid w-50">
                                        <img src={salesforce} />
                                    </div>
                                </div>
                                <div className="card-body p-5">
                                    <h3 className="fw-bold text-dark">
                                        Collect from CRM
                                    </h3>
                                    <p className="text-muted mb-0">
                                        Collect any data from Salesforce in a few clicks
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="expandable-card col-12 col-sm-6 col-lg-4 mt-7">
                            <div className="card h-100 bg-light rounded-2">
                                <div className="card-body p-5 pb-0 my-auto text-primary">
                                    <CookieIcon style={{fontSize: '80px'}} />
                                </div>
                                <div className="card-body p-5">
                                    <h3 className="fw-bold text-dark">
                                        First-party Cookies
                                    </h3>
                                    <p className="text-muted mb-0">
                                        All cookies are first-party, and set on your website's domain
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mt-7">
                            <div className="card h-100 bg-light rounded-2">
                                <div className="card-body p-5 pb-0 my-auto text-primary">
                                    <LanguageOutlinedIcon style={{fontSize: '80px'}} />
                                </div>
                                <div className="card-body p-5">
                                    <h3 className="fw-bold text-dark">
                                        Custom Domains
                                    </h3>
                                    <p className="text-muted mb-0">
                                        Connect your domain to Firstparty with a quick DNS change
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="expandable-card col-12 col-sm-6 col-lg-4 mt-7">
                            <div className="card h-100 bg-light rounded-2">
                                <div className="card-body p-5 pb-0 my-auto text-primary">
                                    <BlockOutlinedIcon style={{fontSize: '80px'}} />
                                </div>
                                <div className="card-body p-5">
                                    <h3 className="fw-bold text-dark">
                                        Mitigate Ad Blockers
                                    </h3>
                                    <p className="text-muted mb-0">
                                        Browsers and ad blockers won't prevent you from collecting data with Firstparty
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="expandable-card col-12 col-sm-6 col-lg-4 mt-7">
                            <div className="card h-100 bg-light rounded-2">
                                <div className="card-body p-5 pb-0 my-auto text-primary">
                                    <DonutLargeOutlinedIcon style={{fontSize: '80px'}} />
                                </div>
                                <div className="card-body p-5">
                                    <h3 className="fw-bold text-dark">
                                        Full Session Support
                                    </h3>
                                    <p className="text-muted mb-0">
                                        Sessions are important on the web, and Firstparty keeps track of them automatically
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="expandable-card col-12 col-sm-6 col-lg-4 mt-7">
                            <div className="card h-100 bg-light rounded-2">
                                <div className="card-body p-5 pb-0 my-auto text-primary">
                                    <CookieIcon style={{fontSize: '80px'}} />
                                </div>
                                <div className="card-body p-5">
                                    <h3 className="fw-bold text-dark">
                                        Cookie Customization
                                    </h3>
                                    <p className="text-muted mb-0">
                                        Decide which cookies to set, from sessions to persistent profile cookies
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mt-7">
                            <div className="card h-100 bg-light rounded-2">
                                <div className="card-body p-5 pb-0 my-auto text-primary">
                                    <FilterAltOutlinedIcon style={{fontSize: '80px'}} />
                                </div>
                                <div className="card-body p-5">
                                    <h3 className="fw-bold text-dark">
                                        Refine with Filters
                                    </h3>
                                    <p className="text-muted mb-0">
                                        Reduce analyzed Events in any report to only those that match your criteria
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mt-7">
                            <div className="card h-100 bg-light rounded-2">
                                <div className="card-body p-5 pb-0 my-auto text-primary">
                                    <AllInclusiveOutlinedIcon style={{fontSize: '80px'}} />
                                </div>
                                <div className="card-body p-5">
                                    <h3 className="fw-bold text-dark">
                                        Unlimited Retention
                                    </h3>
                                    <p className="text-muted mb-0">
                                        We'll never delete your historical data, and you can export it at any time
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="expandable-card col-12 col-sm-6 col-lg-4 mt-7">
                            <div className="card h-100 bg-light rounded-2">
                                <div className="card-body p-5 pb-0 my-auto text-primary">
                                    <div className="lh-1 fw-bolder text-primary" style={{fontSize: "50px"}}>?utm=</div>
                                </div>
                                <div className="card-body p-5">
                                    <h3 className="fw-bold text-dark">
                                        Full UTM Support
                                    </h3>
                                    <p className="text-muted mb-0">
                                        Source, medium, campaign, and more are automatically collected and reportable
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mt-7">
                            <div className="card h-100 bg-light rounded-2">
                                <div className="card-body p-5 pb-0 my-auto text-primary">
                                    <AnalyticsOutlinedIcon style={{fontSize: '80px'}} />
                                </div>
                                <div className="card-body p-5">
                                    <h3 className="fw-bold text-dark">
                                        Custom Reports
                                    </h3>
                                    <p className="text-muted mb-0">
                                        Build any Report you like, including timeseries, funnels, and more
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mt-7">
                            <div className="card h-100 bg-light rounded-2">
                                <div className="card-body p-5 pb-0 my-auto text-primary">
                                    <NumbersOutlinedIcon style={{fontSize: '80px'}} />
                                </div>
                                <div className="card-body p-5">
                                    <h3 className="fw-bold text-dark">
                                        Graphs & KPIs
                                    </h3>
                                    <p className="text-muted mb-0">
                                        Analyze data visually, and highlight your most important metrics
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mt-7">
                            <div className="card h-100 bg-light rounded-2">
                                <div className="card-body p-5 pb-0 my-auto text-primary">
                                    <ImportExportOutlinedIcon style={{fontSize: '80px'}} />
                                </div>
                                <div className="card-body p-5">
                                    <h3 className="fw-bold text-dark">
                                        Multiple Sources
                                    </h3>
                                    <p className="text-muted mb-0">
                                        Collect data fron an unlimited number of websites, databases, and integrations
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mt-7">
                            <div className="card h-100 bg-light rounded-2">
                                <div className="card-body p-5 pb-0 my-auto text-primary">
                                    <SaveOutlinedIcon style={{fontSize: '80px'}} />
                                </div>
                                <div className="card-body p-5">
                                    <h3 className="fw-bold text-dark">
                                        Export to Warehouse
                                    </h3>
                                    <p className="text-muted mb-0">
                                        Send your analytics data to your own data warehouse for further analysis
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mt-7">
                            <div className="card h-100 bg-light rounded-2">
                                <div className="card-body p-5 pb-0 my-auto text-primary">
                                    <StorageOutlinedIcon style={{fontSize: '80px'}} />
                                </div>
                                <div className="card-body p-5">
                                    <h3 className="fw-bold text-dark">
                                        Infinite Infrastructure
                                    </h3>
                                    <p className="text-muted mb-0">
                                        Firstparty is built to scale to any volume of data, and is always improving
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mt-7">
                            <div className="card h-100 bg-light rounded-2">
                                <div className="card-body p-5 pb-0 my-auto text-primary">
                                    <SecurityOutlinedIcon style={{fontSize: '80px'}} />
                                </div>
                                <div className="card-body p-5">
                                    <h3 className="fw-bold text-dark">
                                        Never Shared
                                    </h3>
                                    <p className="text-muted mb-0">
                                        Your data is never shared with third parties
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mt-7">
                            <div className="card h-100 bg-light rounded-2">
                                <div className="card-body p-5 pb-0 my-auto text-primary">
                                    <PeopleOutlineIcon style={{fontSize: '80px'}} />
                                </div>
                                <div className="card-body p-5">
                                    <h3 className="fw-bold text-dark">
                                        Unlimited Users
                                    </h3>
                                    <p className="text-muted mb-0">
                                        Invite as many users as you need to your account
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mt-7">
                            <div className="card h-100 bg-light rounded-2">
                                <div className="card-body p-5 pb-0 my-auto text-primary">
                                    <HttpIcon style={{fontSize: '80px'}} />
                                </div>
                                <div className="card-body p-5">
                                    <h3 className="fw-bold text-dark">
                                        Full API
                                    </h3>
                                    <p className="text-muted mb-0">
                                        All of Firstparty is available via API, including retrieving your data
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/*<CoreConcept*/}
            {/*    title="Events represent actions performed by your website visitor or customer."*/}
            {/*    documentation_link="/docs/analytics/items/events/"*/}
            {/*    api_link="/docs/api/reference/schemas/Event/"*/}
            {/*>*/}
            {/*    <p className="text-light">*/}
            {/*        Events are the central measurement of who, how, and when your website or application is being used.*/}
            {/*        Events may be collected using Firstparty.js, the Firstparty HTTP API, or a Warehouse Source, and can*/}
            {/*        be sent to an unlimited number of Warehouses of your choice.*/}
            {/*    </p>*/}
            {/*</CoreConcept>*/}

            <div className="section bg-black py-7 pb-md-11">
                <div className="container">
                    <div className="row mt-5 pb-10 justify-content-center">
                        <div className="col-12 col-md-10 py-10 text-center">
                            <h6 className="text-uppercase text-light">
                                Get Technical
                            </h6>
                            <h2 className="text-white">Designed for Marketers <br /><strong>and Developers</strong></h2>
                            <p className="text-white mb-6 mb-md-0">Firstparty makes it easy to collect data from your website, warehouse, CRM, app, and more.</p>
                        </div>
                        <div className="col-12 col-md-6 my-auto order-2 order-md-1">
                            <h2 className="h3 text-white-70">Collect Events From Your Website</h2>
                            <p className="text-white-70">
                                Get a detailed understanding of what people are doing when they engage with your product and marketing.
                            </p>
                            <a className="fw-bold text-white text-decoration-none pt-3" href="/docs/analytics/items/events/">Read about Events in the Docs<i className="fe fe-arrow-right ms-1"></i></a>
                        </div>
                        <div className="col-12 col-md-6 img-skewed img-skewed-start order-1 order-md-2">
                            <div className="card rounded-lg mb-6 mb-md-0 img-skewed-item screenshot" style={{background: "rgba(0,0,0,.7)"}}>
                                <div className="card-header border-white-10">
                                    <div className="d-flex">
                                        <span className="bg-danger rounded-circle"
                                              style={{width: "10px", height: "10px"}} />
                                        <span className="bg-warning rounded-circle ms-1"
                                              style={{width: "10px", height: "10px"}} />
                                        <span className="bg-success rounded-circle ms-1"
                                              style={{width: "10px", height: "10px"}} />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <code className="highlight hljs"
                                          style={{minHeight: "123px"}}>
                                        firstparty.track('Page Viewed');
                                        <br />
                                        <br />
                                        firstparty.track('Form Completed', &#123;
                                        <div className="px-3">form_name: 'signup',</div>
                                        <div className="px-3">email: 'stevie@rosebudmotels.com',</div>
                                        &#125;);
                                    </code>
                                    <span className="typed-cursor" aria-hidden="true">|</span>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5 pb-10">
                        <div className="col-12 col-md-6 img-skewed img-skewed-end">
                            <div className="card rounded-lg mb-6 mb-md-0 img-skewed-item screenshot" style={{background: "rgba(0,0,0,.7)"}}>
                                <div className="card-header border-white-10">
                                    <div className="d-flex">
                                        <span className="bg-danger rounded-circle"
                                              style={{width: "10px", height: "10px"}} />
                                        <span className="bg-warning rounded-circle ms-1"
                                              style={{width: "10px", height: "10px"}} />
                                        <span className="bg-success rounded-circle ms-1"
                                              style={{width: "10px", height: "10px"}} />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <code style={{minHeight: "183px"}}>
                                        <div>POST /events/v1/track HTTP/1.1</div>
                                        <div>Content-Type:</div>
                                        <div>Authorization: Bearer asdlkfjsadlfkjasdf</div>
                                        <div>Accept:application/json</div>
                                        <div>Host: app.firstpartyhq.com</div>
                                        <br />
                                        <div>&#123;</div>
                                        <div className="px-3">"event": "Page Viewed",</div>
                                        <div className="px-3">"write_key": "z3cxMqzDJW5eaF8q",</div>
                                        <div className="px-3">"profile_id": "PR123456789012345678901234567890"</div>
                                        <div>&#125;</div>
                                    </code>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 my-auto">
                            <h2 className="h3 text-white-70">Collect Events From Your Server</h2>
                            <p className="text-white-70">
                                Firstparty REST API makes it easy to collect server side events for product analytics. Includes automatic session stitching.
                            </p>
                            <a className="fw-bold text-white text-decoration-none" href="/docs/api/reference/">REST API Docs<i className="fe fe-arrow-right ms-1"></i></a><br />
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 col-md-6 my-auto order-2 order-md-1">
                            <h2 className="h3 text-white-70">Collect Events From Your Database and CRM</h2>
                            <p className="text-white-70">
                                Firstparty creates an Event for every row of data your database or CRM query returns.
                                Connect directly to your Warehouse or Salesforce in just a few clicks.
                            </p>
                            <a className="fw-bold text-white text-decoration-none" href="/docs/analytics/sources/">Read about Sources in the Docs<i className="fe fe-arrow-right ms-1"></i></a>
                        </div>
                        <div className="col-12 col-md-6 img-skewed img-skewed-start order-1 order-md-2">
                            <div className="card rounded-lg mb-6 mb-md-0 img-skewed-item screenshot" style={{background: "rgba(0,0,0,.7)"}}>
                                <div className="card-header border-white-10">
                                    <div className="d-flex">
                                        <span className="bg-danger rounded-circle"
                                              style={{width: "10px", height: "10px"}} />
                                        <span className="bg-warning rounded-circle ms-1"
                                              style={{width: "10px", height: "10px"}} />
                                        <span className="bg-success rounded-circle ms-1"
                                              style={{width: "10px", height: "10px"}} />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <code className="highlight hljs"
                                          data-typed="{&quot;backSpeed&quot;:2, &quot;strings&quot;: [&quot;>&nbsp;$&nbsp;npm&nbsp;install<br/><span class=\&quot;text-success\&quot;>Everything&nbsp;is&nbsp;installed</span><br/><br/>>&nbsp;$&nbsp;npm start<br/><span class=\&quot;text-success\&quot;>scss&nbsp;watching</span><br/><span class=\&quot;text-success\&quot;>LiveReload&nbsp;started</span><br/><span class=\&quot;text-success\&quot;>Opening&nbsp;localhost:8080</span><br/><br/>>&nbsp;$&nbsp;that’s&nbsp;it<br/><span class=\&quot;text-success\&quot;>Yup,&nbsp;that’s&nbsp;it.</span>&quot;]}"
                                          style={{minHeight: "183px"}}>
                                        SELECT
                                        <div className="px-3">
                                            contacts.email, <br />contacts.facebook_click_id, <br />opportunities.value
                                        </div>
                                        FROM
                                        <div className="px-3">contacts</div>
                                        JOIN
                                        <div className="px-3">opportunities</div>
                                        ON
                                        <div className="px-3">
                                            opportunities.account_id = contacts.account_id
                                        </div>
                                    </code>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pb-7 mt-10 justify-content-center">
                        <div className="col-12 col-lg-10 text-center">
                            <p className="lead text-white-70">
                                Firstparty is built API-first, and with the ability to write custom code and queries
                                there's no limit to what your team can build to better serve your customers.
                            </p>
                            <a className="btn btn-white shadow lift mt-2" href="/signup/">
                                Get Started for Free<i className="fe fe-arrow-right ms-2" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <section className="py-8 py-md-11">
                <Container>
                    <Row className="justify-content-between align-items-center">
                        <Col xs={12} md={6} className="mb-8 mb-md-0">
                            <img className="screenshot img-fluid mw-md-130 float-end"
                                 src={firstparty_analytics_dashboard} />
                        </Col>
                        <Col xs={12} md={6}>
                            <h2>
                                See All of Your Traffic (and Conversions!)
                            </h2>
                            <p className="fs-lg text-gray-700 mb-6">
                                Firstparty avoids most browser restrictions and ad blockers, so you can collect <strong>all</strong> of the data you need to make great marketing decisions
                            </p>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p className="text-primary">
                                            See all of your traffic
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p className="text-primary mb-lg-0">
                                            Import Conversions from Salesforce & more
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex">
                                        <span className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                        <i className="fe fe-check"></i>
                                    </span>
                                        <p className="text-primary">
                                            Avoid ad blockers
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft me-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p className="text-primary mb-0">
                                            Supports all browsers
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="py-8 py-md-11">
                <Container>
                    <Row className="justify-content-between align-items-center">
                        <Col xs={12} md={6}>
                            <h2>
                                Understand the Customer Journey
                            </h2>
                            <p className="fs-lg text-gray-700 mb-6">
                                Funnels let you analyze the paths your customers travel before converting. Firstparty makes it easy to build and analyze funnels, and even lets you build custom funnels.
                            </p>
                            <p className="mb-7 mb-md-9">
                                <a className="btn btn-outline-secondary" href="/solutions/analytics/funnels/">
                                    Learn More About Funnels<i className="fe fe-arrow-right ms-2" />
                                </a>
                            </p>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className="img-skewed img-skewed-start mb-8 mb-md-0">
                                <div className="mw-md-130 img-skewed-item card bg-light mb-5" style={{width: '130%'}}>
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <p className="mb-0"><strong>44,931</strong> <br />Homepage Viewed</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="mb-0 small">100% Started</p>
                                                <div className="progress mb-0" style={{height: '8px'}}>
                                                    <div className="progress-bar" role="progressbar" style={{width: "100%"}}
                                                         aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <p className="mb-0"><strong>14,378</strong> <br />Form Viewed</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="mb-0 small">32% of Previous Step</p>
                                                <div className="progress mb-0" style={{height: '8px'}}>
                                                    <div className="progress-bar" role="progressbar" style={{width: "32%"}}
                                                         aria-valuenow="32" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <p className="mb-0"><strong>2,156</strong> <br />Lead Created</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="mb-0 small">15% of Previous Step</p>
                                                <div className="progress mb-0" style={{height: '8px'}}>
                                                    <div className="progress-bar" role="progressbar" style={{width: "15%"}}
                                                         aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <p className="mb-0"><strong>93</strong> <br />Opportunity Created</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="mb-0 small">11% of Previous Step</p>
                                                <div className="progress mb-0" style={{height: '8px'}}>
                                                    <div className="progress-bar" role="progressbar" style={{width: "11%"}}
                                                         aria-valuenow="11" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                                <p className="mb-0 small text-danger">Most Potential Improvement</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <p className="mb-0"><strong>69</strong> <br />Opportunity Won</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="mb-0 small">74% of Previous Step</p>
                                                <div className="progress mb-0" style={{height: '8px'}}>
                                                    <div className="progress-bar" role="progressbar" style={{width: "74%"}}
                                                         aria-valuenow="74" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>

            <PrivacySection />

            <PricingSection />

        </SiteContainer>
    )
}

export default IndexPage
